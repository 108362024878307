import React from "react";

export default function Footer() {
  return (
    <footer className="page-footer" style={{ backgroundColor: "#055968" }}>
      <div className="bk-footer-inner pt--30 pb--30">
        <div className="container" style={{ maxWidth: "80%" }}>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="footer-widget text-var--2">
                <div className="logo">
                  <a href="index.html">
                    <img
                      src="img/logo-3.svg"
                      alt="Property Technology Solutions"
                      style={{ width: "27%" }}
                    />
                  </a>
                </div>
                <div className="footer-inner">
                  <p>Property Technology Solutions B.V.</p>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_mobile--40">
              <div className="footer-widget text-var--2 menu--about">
                <h2 className="widgettitle">About us</h2>
                <div className="footer-menu">
                  <ul className="ft-menu-list bk-hover">
                    <li>
                      <a href="about-us-01.html">About Us</a>
                    </li>
                    <li>
                      <a href="team.html">Team</a>
                    </li>
                    <li>
                      <a href="career.html">Career</a>
                    </li>
                    <li>
                      <a href="services-classic.html">Services</a>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}

            <div className="col-lg-6 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
              <div className="footer-widget text-var--2 menu--contact">
                <h2 className="widgettitle">Contact</h2>
                <div className="footer-address">
                  <div className="bk-hover">
                    <p>
                      PROPERTY TECHNOLOGY SOLUTİONS TURKEY BİLGİ TEKNOLOJİLERİ
                      ANONİM ŞİRKETİ
                    </p>
                    <p>
                      ESENTEPE MAH. BAHAR SOKAK OZDILEK RIVER VYNDHAM GRAND SIT.
                      NO:13
                    </p>
                    <p>
                      <a href="mailto:support@property.tech">support@property.tech</a>
                    </p>
                    <p>
                      <a href="tel:+905322021200">(+90) 532 202 12 00 </a>
                    </p>
                    <p>
                      <a href="tel:02123491000">(0212) 349 10 00</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
