function Preloader() {
  return (
    <div id="page-preloader" className="page-loading clearfix">
      <div className="page-load-inner">
        <div className="preloader-wrap">
          <div className="wrap-2">
            <div className="">
              {" "}
              <img
                src="img/logo-3.svg"
                style={{ width: "20%" }}
                alt="Brook Preloader"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preloader;
